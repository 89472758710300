/* template-color */

.scroll-top{
  background-color: #0a509f;
}

.handle-preloader{
	background-color: #0a509f;
}

.preloader-close{
	color: #0a509f;
}

.theme-btn.btn-one{
	background-color: #0a509f;
}

.theme-btn:before{
	background-color: #002c8f;
}

.main-header .nav-right .cart-box a span{
	background-color: #0a509f;
}

.main-header .search-box-btn:hover{
  color: #0a509f;
}

.main-header .nav-right .cart-box a:hover{
  color: #0a509f;
}

.main-menu .navigation > li.current > a,
.main-menu .navigation > li:hover > a{
  color: #0a509f;
}

.main-menu .navigation > li > ul > li > a:hover,
.main-menu .navigation > li > .megamenu li > a:hover{
	color: #0a509f;
}

.main-menu .navigation > li > ul > li > ul > li > a:hover{
  color: #0a509f;
}

.banner-carousel .content-box .btn-box .theme-btn:hover{
  color: #0a509f !important;
}

.feature-block-one .inner-box .icon-box{
	color: #0a509f;
}

.service-block-one .inner-box .icon-box{
	color: #0a509f;
}

.service-block-one .inner-box h4 a:hover{
  color: #0a509f;
}

.bg-color-2{
	background-color: #002c8f;
}

.content_block_2 .content-box .single-item .icon-box{
	background-color: #0a509f;
}

.theme-btn.btn-two:before{
  background-color: #0a509f;
}

.theme-btn.btn-two:hover{
	border-color: #0a509f;
}

.shop-block-one .inner-box .lower-content h4 a:hover{
  color: #0a509f;
}

.shop-block-one .inner-box .lower-content h6{
	color: #0a509f;
}

.testimonial-block-one .inner-box .rating li{
	color: #f1c734;
}

.testimonial-block-one .inner-box .designation{
	color: #0a509f;
}

.testimonial-section .owl-dots .owl-dot.active span,
.testimonial-section .owl-dots span:hover{
	background-color: #0a509f !important;
}

.team-block-one .inner-box .image-box .social-links li a:hover{
	background-color: #0a509f;
}

.team-block-one .inner-box .lower-content h4 a:hover{
  color: #0a509f;
}

.team-block-one .inner-box .lower-content .share-box a:hover{
	background-color: #0a509f;
}

.content_block_3 .content-box .theme-btn:hover{
  color: #0a509f !important;
}

.news-block-one .inner-box .lower-content .category a{
	color: #0a509f;
}

.news-block-one .inner-box .lower-content h4 a:hover{
  color: #0a509f;
}

.news-block-one .inner-box .lower-content .post-info li a:hover{
  color: #0a509f;
}

.main-footer .footer-top .text h2 span{
  color: #0a509f;
}

.main-footer .footer-top .support-box a{
	background-color: #0a509f;
}

.main-footer .contact-widget .info-list li a:hover{
  color: #0a509f;
}

.main-footer .contact-widget .info-list li i{
	color: #0a509f;
}

.main-footer .links-widget .links-list li a:hover{
  color: #0a509f;
}

.main-footer .links-widget .links-list li a:before{
	background-color: #0a509f;
}

.main-footer .subscribe-widget .form-group button{
	background-color: #0a509f;
}

.main-footer .subscribe-widget .form-group input:focus{
  border-color: #0a509f !important;
}

.footer-bottom .copyright p a{
  color: #0a509f;
}

.footer-bottom .social-links li a:hover{
  background-color: #0a509f;
}

.footer-bottom .footer-nav li a:hover{
  color: #0a509f;
}

.banner-carousel .owl-nav button:hover{
  background-color: #0a509f;
}

.banner-style-two .banner-carousel .content-box .btn-box .banner-btn:before{
  background-color: #0a509f;
}

.banner-style-two .banner-carousel .content-box .btn-box .banner-btn:hover{
  border-color: #0a509f;
}

.content_block_4 .content-box .list li:before{
	color: #0a509f;
}

.content_block_5 .form-inner .form-group input:focus,
.content_block_5 .form-inner .form-group textarea:focus{
  border-color: #0a509f !important;
}

.image_block_2 .image-box .text h2{
	color: #0a509f;
}

.pricing-section .tab-btn-box .tab-btns li.active-btn{
  background-color: #0a509f;
  border-color: #0a509f;
}

.pricing-block-one .pricing-table .price-box{
	background-color: #0a509f;
}

.pricing-block-one .pricing-table .table-content .feature-list li:before{
	color: #002c8f;
}

.testimonial-style-two .owl-dots .owl-dot.active span,
.testimonial-style-two .owl-dots span:hover{
	background-color: #0a509f !important;
}

.testimonial-block-two .inner-box .rating li{
	color: #f1c734;
}

.testimonial-block-two .inner-box .designation{
	color: #0a509f;
}

.page-title .bread-crumb li{
	color: #0a509f;
}

.page-title .bread-crumb li a:hover{
  color: #0a509f;
}

.testimonial-style-three .inner-box .rating li{
	color: #f1c734;
}

.testimonial-style-three .inner-box .designation{
	color: #0a509f;
}

.accordion-box .block .acc-btn.active h4{
  background-color: #0a509f;
}

.faq-sidebar .form-inner form .form-group input:focus,
.faq-sidebar .form-inner form .form-group textarea:focus{
  border-color: #0a509f !important;
}

.sortable-masonry .filters .filter-tabs li.active{
  background-color: #0a509f;
  border-color: #0a509f;
}

.service-block-two .inner-box .icon-box .icon{
	background-color: #0a509f;
}

.service-block-two .inner-box h4 a:hover{
  color: #0a509f;
}

.service-details-content .content-two .inner-box .single-item .icon-box{
	background-color: #0a509f;
}

.service-sidebar .category-list li a.current,
.service-sidebar .category-list li a:hover{
  border-color: #0a509f;
}

.service-sidebar .category-list li a:before{
	background-color: #0a509f;
}

.default-sidebar .sidebar-search .form-group input:focus{
  border-color: #0a509f !important;
}

.default-sidebar .sidebar-search .form-group input:focus + button,
.default-sidebar .sidebar-search .form-group button:hover{
	color: #0a509f;
}

.default-sidebar .sidebar-category .category-list li a i{
	color: #0a509f;
}

.default-sidebar .sidebar-category .category-list li a:hover{
  color: #0a509f;
}

.range-slider .filter-btn{
	background-color: #0a509f;
}

.default-sidebar .tags-widget .tags-list li a:hover{
	border-color: #0a509f;
	background-color: #0a509f;
}

.product-details-content .image-box .preview-link a:hover{
  border-color: #0a509f;
	background-color: #0a509f;
}

.product-details-content .product-details .rating li{
	color: #ffb83d;
}

.product-details-content .product-details .customer-rating .review a:hover{
	color: #0a509f;
}

.product-details-content .product-details .addto-cart-box .clearfix li a:hover{
  border-color: #0a509f;
	background-color: #0a509f;
}

.product-details-content .product-details .other-option .social-links li a:hover{
  color: #0a509f;
}

.shop-details .product-discription .tabs-content .customer-review .single-review .inner .rating li{
	color: #ffb83d;
}

.shop-details .product-discription .tabs-content .customer-comments .rating-box .rating li{
	color: #ffb83d;
}

.shop-details .product-discription .tabs-content .customer-comments .comment-form .form-group input:focus,
.shop-details .product-discription .tabs-content .customer-comments .comment-form .form-group textarea:focus{
	border-color: #0a509f !important;
}

.cart-section .cart-table tbody tr .prod-column .column-box .remove-btn:hover{
	background-color: #0a509f;
	border-color: #0a509f;
}

.cart-section .othre-content .coupon-box button{
	background-color: #0a509f;
}

.checkout-section .upper-box .single-box a{
  color: #0a509f;
}

.checkout-section .billing-form .form-group input:focus{
  border-color: #0a509f !important;
}

.pagination li a:hover,
.pagination li a.current{
  background-color: #0a509f;
	border-color: #0a509f;
}

.blog-sidebar .post-widget .post h5 a:hover{
  color: #0a509f;
}

.blog-sidebar .archives-widget .archives-list li a:hover{
  color: #0a509f;
}

.news-block-one .inner-box .lower-content h3 a:hover{
  color: #0a509f;
}

.blog-details-content .content-one blockquote .icon-box{
	color: #0a509f;
}

.blog-details-content .post-share-option .social-links li a:hover{
  background-color: #0a509f;
}

.blog-details-content .comment-box .comment .reply-btn:hover{
  color: #0a509f;
}

.default-form .form-group input:focus,
.default-form .form-group textarea:focus{
  border-color: #0a509f !important;
}

.contact-style-two .info-list li i{
	color: #0a509f;
}

.contact-style-two .info-list li p a:hover{
  color: #0a509f;
}