/* Responsive Css */



@media only screen and (max-width: 1599px){

  .main-header .outer-box{
    padding: 0px 30px;
  }

  .main-header .nav-right li.btn-box{
    display: none;
  }

  .main-header .nav-right li.cart-box{
    margin-right: 0px;
  }

  .rtl .main-header .nav-right li.cart-box{
    margin-left: 0px;
  }

  .main-header .header-lower .shape{
    background-size: cover;
  }

}


@media only screen and (max-width: 1399px){



}


@media only screen and (max-width: 1200px){

  .main-menu,
  .sticky-header,
  .main-header.style-one .outer-container:before{
    display: none !important;
  }

  .menu-area .mobile-nav-toggler {
    display: block;
    padding: 10px;
  }

  .megamenu ul li:first-child{
    display: none;
  }

  .about-section .image-box img{
    max-width: 100%;
  }

  .about-section .content_block_1 .content-box{
    margin-left: 0px;
  }

  .chooseus-section .image-box{
    margin-right: 0px;
  }

  .team-block-one .inner-box .image-box .social-links li{
    margin: 0px 3px;
  }

  .team-block-one .inner-box{
    padding-left: 30px;
    padding-right: 30px;
  }

  .cta-section .pattern-layer .pattern-3,
  .cta-section .pattern-layer .pattern-4{
    background-size: cover;
  }

  .cta-section .image-box img{
    max-width: 100%;
  }

  .cta-section .image-box{
    margin-right: 0px;
  }

  .main-footer .footer-top .text{
    float: none;
    display: block;
    margin-bottom: 15px;
  }

  .main-footer .footer-top .support-box{
    float: none;
    display: block;
  }

  .main-footer .pattern-layer .pattern-3{
    background-size: cover;
  }

  .banner-carousel .inner-box .image-box,
  .banner-carousel .owl-nav{
    display: none;
  }

  .banner-carousel .content-box{
    max-width: 100%;
  }

  .about-style-two .content-box .text{
    margin-left: 0px;
  }

  .image_block_2 .image-box .image-1 img{
    max-width: 100%;
  }

  .testimonial-style-two .thumb-box{
    display: none;
  }

  .image_block_1 .image-box{
    padding-right: 0px;
  }

  .rtl .main-footer .footer-top .support-box{
    float: right !important;
  }

  .faq-sidebar{
    margin-left: 0px;
  }

  .page-title .shape{
    background-size: cover;
  }

  .service-sidebar{
    margin-left: 0px;
  }

  .shop-sidebar{
    margin-right: 0px;
  }

  .product-details-content .product-details{
    margin-left: 0px;
  }

  .blog-sidebar{
    margin-left: 0px;
  }

  .contact-style-two .info-inner .shape{
    right: 30px;
  }

  .feature-section.light .pattern-layer .pattern-1{
    background-size: cover;
  }


}



@media only screen and (min-width: 768px){
  .main-menu .navigation > li > ul,
  .main-menu .navigation > li > ul > li > ul,
  .main-menu .navigation > li > .megamenu{
    display:block !important;
    visibility:hidden;
    opacity:0;
  }
}



@media only screen and (max-width: 991px){

  .feature-block-one .inner-box{
    margin-bottom: 30px;
  }

  .feature-section .inner-container{
    padding-bottom: 63px;
  }

  .about-section .image-box{
    margin-bottom: 30px;
  }

  .sec-title h2 br{
    display: none !important;
  }

  .service-section{
    padding-top: 150px;
  }

  .service-section .image-layer{
    display: none;
  }

  .service-section .left-column{
    padding: 0px;
  }

  .service-block-one{
    margin: 0 auto !important;
    left: 0px !important;
    right: 0px !important;
  }

  .service-section .right-column{
    padding-left: 0px;
  }

  .shop-block-one .inner-box{
    margin-bottom: 30px;
  }

  .shop-section{
    padding-bottom: 120px;
  }

  .testimonial-section .owl-dots{
    display: none;
  }

  .team-block-one .inner-box{
    margin-bottom: 30px;
  }

  .team-section{
    padding-bottom: 120px;
  }

  .cta-section .image-box img{
    float: none;
  }

  .cta-section .image-box{
    margin-bottom: 30px;
  }

  .news-block-one .inner-box{
    margin-bottom: 30px;
  }

  .news-section{
    padding-bottom: 120px;
  }

  .main-footer .footer-widget{
    margin: 0px 0px 30px 0px !important;
  }

  .main-footer .widget-section{
    padding-bottom: 50px;
  }

  .about-style-two .sec-title{
    margin-bottom: 20px;
  }

  .image_block_1 .image-box{
    display: none;
  }

  .cta-style-two{
    padding: 145px 0px 150px 0px;
  }

  .contact-section .title-inner{
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .image_block_2 .image-box{
    margin-right: 0px;
    margin-bottom: 60px;
  }

  .pricing-section .inner-box{
    padding-left: 0px;
    margin-top: 50px;
  }

  .pricing-section .pr-content .pr-tab {
    -webkit-transform: scale(0.9,0.9) translateX(300px);
    -ms-transform: scale(0.9,0.9) translateX(300px);
    -o-transform: scale(0.9,0.9) translateX(300px);
    -moz-transform: scale(0.9,0.9) translateX(300px);
    transform: scale(0.9,0.9) translateX(300px);
  }

  .rtl .cta-style-two .content_block_4 .content-box{
    margin-right: 0px;
  }

  .testimonial-block-one .inner-box{
    margin-bottom: 30px;
  }

  .testimonial-section.testimonial-page{
    padding-bottom: 120px;
  }

  .testimonial-style-two.testimonial-page{
    padding: 0px;
    background: transparent;
  }

  .testimonial-style-two .map-layer{
    display: none;
  }

  .testimonial-style-two .pattern-layer{
    display: none;
  }

  .testimonial-style-two .owl-dots{
    display: none;
  }

  .testimonial-style-three .inner-box{
    margin-bottom: 30px;
  }

  .testimonial-style-three{
    padding-bottom: 120px;
  }

  .faq-content{
    margin-right: 0px;
    margin-bottom: 40px;
  }

  .service-details-content{
    margin-bottom: 30px;
  }

  .shop-sidebar{
    margin-bottom: 30px;
  }

  .product-details-content .image-box{
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .checkout-section .additional-info{
    margin-bottom: 50px;
  }

  .blog-sidebar{
    margin-top: 50px;
  }

  .contact-style-two .info-inner .shape{
    display: none;
  }

}


@media only screen and (max-width: 767px){

  .sec-pad{
    padding: 65px 0px 70px 0px;
  }

  .banner-carousel .content-box h2{
    font-size: 36px;
    line-height: 44px;
  }

  .banner-carousel .content-box{
    padding: 180px 0px 200px 0px;
  }

  .feature-block-one .inner-box .shape{
    display: none;
  }

  .feature-section .inner-container{
    padding: 70px 30px 40px 30px;
  }

  .about-section{
    padding: 70px 0px;
  }

  .sec-title h2{
    font-size: 32px;
    line-height: 40px;
  }

  .service-block-one .inner-box{
    margin-bottom: 30px;
  }

  .service-section .shape{
    display: none;
  }

  .service-section{
    padding: 70px 0px 40px 0px;
  }

  .chooseus-section .image-box{
    top: 0px;
    margin-top: 30px;
  }

  .chooseus-section{
    padding: 70px 0px 120px 0px;
  }

  .shop-section,
  .news-section{
    padding: 65px 0px 40px 0px;
  }

  .testimonial-section .shape-layer{
    display: none;
  }

  .testimonial-section{
    padding: 70px 0px 40px 0px;
  }

  .team-section{
    padding: 65px 0px 40px 0px;
  }

  .main-footer .pattern-layer{
    display: none;
  }

  .main-footer .footer-top{
    padding-top: 90px;
  }

  .footer-bottom .bottom-inner{
    display: block;
    text-align: center;
  }

  .banner-style-two .banner-carousel .content-box{
    padding: 200px 0px;
  }

  .feature-section.alternat-2{
    padding: 65px 0px 40px 0px;
  }

  .about-style-two .pattern-layer{
    display: none;
  }

  .about-style-two{
    padding-top: 70px;
    background-color: #f2f9ff;
  }

  .pt-145{
    padding-top: 65px !important;
  }

  .cta-style-two .bg-layer{
    display: none;
  }

  .cta-style-two{
    padding: 70px 0px;
  }

  .testimonial-section.alternat-2{
    padding: 70px 0px 40px 0px;
  }

  .testimonial-section.alternat-2 .owl-dots{
    display: none;
  }

  .shop-section .pattern-layer{
    display: none;
  }

  .pricing-section{
    padding-bottom: 70px;
  }

  .testimonial-style-two{
    padding: 70px 0px 300px 0px;
  }

  .page-title{
    padding: 150px 0px 90px 0px;
  }

  .page-title h1{
    font-size: 40px;
    line-height: 50px;
  }

  .team-page-section .team-block-one .inner-box{
    margin-bottom: 30px;
  }

  .pt-170{
    padding-top: 70px;
  }

  .testimonial-section.testimonial-page{
    padding: 70px 0px 40px 0px;
  }

  .testimonial-style-two.testimonial-page{
    padding-bottom: 70px;
  }

  .testimonial-style-three{
    padding-top: 0px;
    padding-bottom: 40px;
  }

  .faq-page-section{
    padding: 70px 0px;
  }

  .gallery-section,
  .service-page-section{
    padding: 70px 0px 40px 0px;
  }

  .service-details{
    padding: 70px 0px;
  }

  .service-details-content .content-three .image-box .image{
    margin-bottom: 30px;
  }

  .service-details-content h2{
    font-size: 30px;
    line-height: 40px;
  }

  .shop-page-section{
    padding: 70px 0px 40px 0px;
  }

  .shop-details{
    padding: 70px 0px 40px 0px;
  }

  .cart-section .othre-content .coupon-box{
    float: none;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .cart-section .othre-content .update-btn{
    float: none;
  }

  .cart-section .othre-content .update-btn button{
    width: 100%;
  }

  .cart-section{
    padding: 70px 0px;
  }

  .checkout-section{
    padding: 70px 0px;
  }

  .sidebar-page-container{
    padding: 70px 0px;
  }

  .blog-details-content .content-two .image-box .image{
    margin-bottom: 30px;
  }

  .contact-style-two{
    padding: 70px 0px;
  }

  .google-map-section{
    padding-bottom: 70px;
  }

  .service-details-content .content-three .image-box{
    margin-bottom: 40px;
  }

  .chooseus-section .content_block_2 .content-box{
    margin-right: 0px;
  }

  .cta-style-two .content_block_4 .content-box{
    margin-left: 0px;
  }

  .sec-pad-2{
    padding: 70px 0px !important;
  }

  .shop-details .product-discription .tabs-content .customer-review{
    padding-right: 0px;
    border-right: none;
  }


}

@media only screen and (max-width: 599px){

  .main-header .outer-box{
    display: block;
  }

  .main-header .logo-box .logo{
    margin: 0 auto;
  }

  .main-header .nav-right{
    position: absolute;
    left: 20px;
    bottom: -2px;
    padding: 0px;
    z-index: 1;
  }

  .main-header .outer-box{
    padding: 0px 15px;
  }

  .main-header .search-box-outer .dropdown-menu{
    left: 0px !important;
  }

  .banner-style-three .banner-carousel .content-box{
    padding-top: 250px;
  }

  .sortable-masonry .filters .filter-tabs li{
    margin-bottom: 10px;
  }

  .error-page-section .inner-box h2 br{
    display: none;
  }

  .error-page-section .inner-box h1{
    font-size: 120px;
    line-height: 120px;
  }

  .error-page-section{
    padding: 100px 0px;
  }

  .shop-page-section .item-shorting .left-column,
  .shop-page-section .item-shorting .right-column{
    float: none;
    display: block;
    text-align: center;
  }

  .shop-page-section .item-shorting .left-column .text{
    float: none;
  }

  .shop-page-section .item-shorting .right-column .short-box, 
  .shop-page-section .item-shorting .right-column .filter-box{
    float: none;
  }

  .shop-page-section .item-shorting .right-column .short-box{
    max-width: 275px;
    margin: 0 auto;
  }

  .product-details-content .product-details .addto-cart-box .clearfix li{
    margin-bottom: 10px;
  }

  .blog-details-content .content-one blockquote{
    padding-left: 30px;
    padding-right: 30px;
  }

  .blog-details-content .content-one blockquote .shape{
    display: none;
  }

  .blog-details-content .content-one blockquote .icon-box{
    position: relative;
    left: 0px;
    top: 0px;
    margin-bottom: 15px;
  }

  .blog-details-content .content-two .image-box{
    margin-bottom: 40px;
  }

  .product-details-content{
    padding-bottom: 80px;
  }

  .shop-details .product-discription{
    padding-top: 70px;
    padding-bottom: 80px;
  }

  .shop-details .related-product{
    padding-top: 70px;
  }

  .shop-details .product-discription .tabs-content .customer-review{
    padding-bottom: 30px;
  }

}


@media only screen and (max-width: 499px){

  .mobile-menu{
    width: 100%;
  }

  .testimonial-block-one .inner-box{
    padding-left: 30px;
  }

  .testimonial-block-one .inner-box .author-thumb{
    position: relative;
    left: 0px;
    top: 0px;
    margin-bottom: 15px;
  }

  .news-block-one .inner-box{
    padding-left: 30px;
    padding-right: 30px;
  }

  .main-header .search-box-outer .dropdown-menu{
    width: 280px;
  }

  .about-style-two .video-inner .inner-box{
    padding: 120px 0px;
  }

  .content_block_5 .form-inner{
    padding: 50px 30px;
  }

  .feature-section.light .title-text h2 br{
    display: none;
  }

  .video-section .video-inner .inner-box{
    padding: 120px 0px;
  }

  .rtl .testimonial-block-one .inner-box{
    padding-right: 30px;
  }

  .rtl .testimonial-block-one .inner-box .author-thumb{
    right: 0px;
    text-align: right;
  }

  .feature-section .title-text h2 br{
    display: none;
  }

  .checkout-section .order-info .order-list li .single-box span{
    float: none;
  }

  .checkout-section .payment-info .payment-inner{
    padding-left: 20px;
    padding-right: 20px;
  }

}


@media only screen and (max-width: 399px){

  .banner-carousel .content-box .btn-box .theme-btn.btn-one{
    margin-bottom: 10px;
  }

  .cart-section .othre-content .coupon-box input[type='text']{
    padding-right: 30px;
    margin-bottom: 10px;
  }

  .cart-section .othre-content .coupon-box button{
    position: relative;
    border-radius: 30px;
    width: 100%;
  }

}